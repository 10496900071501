<template>
    <div class="gxjy">
      <gxjyheader></gxjyheader>
      <div class="bgall">
        <div class=" content">
          <el-row>
            <el-col span="7">
              <div class="toplist bg1">
                <div class="gxbottom gxbg1">
                  <div class="tagright">供</div>
                  <div class="tag1"><el-tag size="small" type="danger" effect="light">供方市场</el-tag></div>
                  <div class="color1">产品销售</div>
                  <div>
                    累计发布供应 <span class="tiao">{{datas.inventoryCount}}</span>条
                  </div>
                  <el-divider></el-divider>
                  <el-button type="primary" @click="gohref(1)" plain style="background: none">发布产品</el-button>
                </div>
              </div>
            </el-col>
            <el-col span="7" style="margin: 0 60px">
              <div class="toplist bg2">
                <div class="gxbottom gxbg2">
                  <div class="tagright">需</div>
                  <div class="tag1"><el-tag size="small" type="success" effect="light">需方市场</el-tag></div>
                  <div class="color2">采购需求</div>
                  <div>
                    累计发布供应 <span class="tiao">{{datas.purchaseCount}}</span>条
                  </div>
                  <el-divider></el-divider>
                  <el-button type="primary" @click="gohref(2)" plain style="background: none">发布需求</el-button>
                </div>
              </div>
            </el-col>
            <el-col span="7">
              <div class="toplist bg2">
                <div class="gxbottom gxbg2">
                  <div class="tagright">需</div>
                  <div class="tag1"><el-tag size="small" type="success" effect="light">需方市场</el-tag></div>
                  <div class="color2">招标需求</div>
                  <div>
                    累计发布供应 <span class="tiao">{{datas.tenderingBiddingCount}}</span>条
                  </div>
                  <el-divider></el-divider>
                  <el-button type="primary" @click="gohref(3)" plain style="background: none">发布需求</el-button>
                </div>
              </div>
            </el-col>
          </el-row>
          <div class="wyrz" v-if="!common.getSessionStorage('userInfo')">
            入驻平台，发布供需信息，可以让您的产品和服务迅速找到合适的供应商或采购商，实现供需信息的高效对接，提升交易效率!
            <div class="btn" @click="gohref(4)">我要入驻</div>
          </div>
        </div>
      </div>
      <div  class="img1">
        <div class="content gfsc">
          <div><span class="gfsctitle">供方市场</span><el-tag  size="small" type="danger" effect="light">产品</el-tag><span style="float: right;margin-top: 25px;cursor: pointer" @click="goqb">查看全部</span></div>
          <div class="shichang flex">
            <div class="scleft">
              <div class="ul" :class="{'checked':number==index}" @click="choosecheck(index)" v-for="(li,index) in industryList" :key="index">
                  {{li.title}}
              </div>
            </div>
            <div class="flex-1" style="padding: 20px;overflow: hidden">
              <div style="width: 300px;margin-bottom: 20px">
                <el-input placeholder="请输入关键词搜索" v-model="keys">
                  <el-button  slot="append" icon="el-icon-search" @click="loadlist(true)"></el-button>
                </el-input>
              </div>
              <div class="ul1" v-for="(li,index) in items" :key="index" @click="goxq(li)">
                <div class="images"><el-image fit="cover" :src="li.images"></el-image></div>
                <div class="ultitle text-overflow">{{li.title}}</div>
              </div>
              <el-empty v-if="items.length==0"></el-empty>
              <div class="fenye">
                <el-pagination
                    layout="prev, pager, next"
                    @current-change="handleCurrentChange"
                    :current-page="page.current"
                    :page-size="page.size"
                    :total="page.total">
                </el-pagination>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="bgf" >
        <div class="xfscbg1"></div>
        <div class="content xfsc">
          <div><span class="gfsctitle">需方市场</span><el-tag  size="small" type="success" effect="light">采购</el-tag></div>
          <el-divider ></el-divider>
          <div  class="xfsch1">
            <el-tabs v-model="activeName" style="color:#fff;">
              <el-tab-pane label="采购" name="1">
                <div style="margin-top: 35px">
                  自采+集采两种采购方式，一站式解决企业采购需求，让企业运营更顺畅!
                </div>
              </el-tab-pane>
              <el-tab-pane label="招标" name="2">
                <div style="margin-top: 35px">提高效率，快速对接项目联系人</div>
              </el-tab-pane>
            </el-tabs>
            <div><div class="xfscbtn" @click="ckxq()">查看详情</div></div>
          </div>
        </div>
        <div style="width: 55%; height: 358px;position: absolute;  right: 0px;  z-index: 99; top: 63px;"><el-image :src="img4"></el-image></div>
      </div>
      <div class="bgf1">
        <div class="content">
          <div class="gytitle">库存供应</div>
          <div class="flex ">
            <div class="gyimage" v-for="(li,index) in inventoryList" :key="index">  <el-image :src="li.image"></el-image> <div class="gybtn" @click="gybtn(li)">查看详情</div></div>
          </div>
        </div>
      </div>
      <div class="bgf">
        <div class="content zixun">
          <div><span class="zixuntitle">供需资讯动态</span></div>
          <div class="zixuncontent">
            <el-tabs v-model="gxName">
              <el-tab-pane label="供应" name="3" >
                <div class="flex" style="float: left;margin: 15px;cursor: pointer" v-for="(item,index) in supplyList" @click="gozixunxq(item)">
                  <div style="width: 550px">
                    <div  style="height: 20px;" class="text-overflow"><i class="el-icon-document" style="margin-right: 8px"></i>{{item.title}}</div>
                  </div>
<!--                   <div style="width: 176px;height: 132px;margin-right: 15px"><el-image></el-image></div>-->
<!--                  <div style="width: 370px">-->
<!--                    <div  style="height: 40px;" class="ellipsis2">sadasdasd</div>-->
<!--                    <div style="margin: 40px 0 10px 0"><el-tag size="small" type="danger" effect="light">供方市场</el-tag></div>-->
<!--                    <div class="text-overflow" style="color: #CCCCCC">dsadsa   <span style="float: right">dsadasd</span></div>-->
<!--                  </div>-->
                </div>
              </el-tab-pane>
              <el-tab-pane label="需求" name="2">
                <div class="flex" style="float: left;margin: 15px;cursor: pointer" v-for="(item,index) in demandList" @click="gozixunxq(item)">
                  <div style="width: 550px">
                    <div  style="height: 20px;" class="text-overflow"><i class="el-icon-document" style="margin-right: 8px"></i>{{item.title}}</div>
                  </div>
                </div>
              </el-tab-pane>
            </el-tabs>
            <el-button class="zixunbtn" type="primary" @click="ckquanbu">查看全部</el-button>
          </div>
        </div>
      </div>
      <div class="bgf1">
        <div class="content">
          <div class="gytitle">招标咨询动态</div>
          <div>
            <el-table
                :data="consultList"
                style="width: 100%">
              <el-table-column prop="enterpriseName" label="企业名称">
              </el-table-column>
              <el-table-column prop="name" label="供需类型">
                供方
              </el-table-column>
              <el-table-column prop="contacts" label="咨询人">
              </el-table-column>
              <el-table-column prop="phone" label="咨询人联系方式">
              </el-table-column>
            </el-table>

          </div>
<!--          <div style="text-align: center;margin: 25px 0">  <el-button type="primary">查看全部</el-button></div>-->
        </div>
      </div>
      <div class="bgf1 bgf" style="height: 500px">
        <div class="content">
          <div class="gytitle">供需对接活动</div>
          <div class="flex">
            <div class="hdimage" ref="hdimage" @mouseenter="remove" :class="{'remove-class':index==0}"  v-for="(li,index) in activityList">
              <el-image :src="li.image" fit="cover"></el-image>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import gxjyheader from './gxjyheader'
import config from '../../utils/config'
import {supplydemandindex} from "../../request/moudle/demand";
export default {
  name: "index",
  components:{
    gxjyheader
  },
  data(){
    return {
      page:{
        current:1,
        size:9,
        total:0
      },
      inventoryList:[],
      supplyList:[],
      demandList:[],
      consultList:[],
      activityList:[],
      number:0,
      img4:require('../../assets/gxjy/img4.png'),
      arr:[{h1:'111'}],
      tableData:[],
      gxName:'3',
      activeName:'1',
      industryList:[],
      keyword:'',
      keys:'',
      datas:'',
      items:[]
    }
  },
  created() {
    this.loadconfig()
  },
  methods:{
    loadconfig(){
      this.$api.demand.supplydemandindex().then((res)=>{
          this.datas=res.data
          this.industryList=res.data.industryList
          this.industryList.unshift({ title: '全部', id: '' })
          this.inventoryList=res.data.inventoryList
        this.demandList=res.data.demandList
        this.supplyList=res.data.supplyList
        this.consultList=res.data.consultList
        this.activityList=res.data.activityList
        this.loadlist()
      })
    },
    loadlist(bol){
      if(bol){
        this.page.current=1
      }
      const obj={
        productName:this.keys,
        industryId:this.industryList[this.number].id
      }
      obj.current=this.page.current
      obj.size=this.page.size
      this.$api.demand.inventorylist(obj).then((res)=>{
        this.items=res.data.records
        this.page.total=res.data.total
      })
    },
    handleCurrentChange(val) {
      this.page.current=val
      this.loadlist()
    },
    choosecheck(li){
      this.number=li
      this.loadlist(true)
    },
    remove(e){
      let hdimage=this.$refs.hdimage
      for (const val of hdimage){
        val.className='hdimage'
      }
      e.target.className='hdimage remove-class'
    },
    ckquanbu(){
      this.$router.push({
        path:'/zixun',
        query:{
          typeId:this.gxName
        }
      })
    },
    goxq(li){
      this.$router.push({
        path:'/kucunxq',
        query:{
          id:li.id
        }
      })
    },
    goqb(){
      this.$router.push({
        path:'/kucun'
      })
    },
    gozixunxq(item){
      if(this.gxName==2){
        this.$router.push({
          path:'/caigouxq',
          query:{
            id:item.id
          }
        })
      }else{
        this.$router.push({
          path:'/kucunxq',
          query:{
            id:item.id
          }
        })
      }

    },
    ckxq(){
      if(this.activeName==1){
        this.$router.push({
          path:'/caigou'
        })
      }else{
        this.$router.push({
          path:'/zhaobiao'
        })
      }
    },
    gybtn(item){
        if(item.link){
            if(item.link.match('http')){
              window.open(item.link,'_blank')
            }else{
              this.$router.push({
                path:`${item.link}`
              })
            }
        }
    },
    gohref(num){
        if(num==1){
          window.open(config.adminhref+'#/demand/kucun','_blank')
        }else if(num==2){
          window.open(config.adminhref+'#/demand/caigou','_blank')
        }else if(num==3){
          window.open(config.adminhref+'#/demand/ztbiao','_blank')
        }else if(num==4){
          this.$router.push({
            path:'/zhuce'
          })
        }
    },
    go(num){
      if(num==1){
        this.$router.push({
          path:'/caigou'
        })
      }else{
        this.$router.push({
          path:'/zhaobiao'
        })
      }
    },
    goto(num){
      if(num==1){
        this.$router.push({
          path:'/caigou'
        })
      }else{
        this.$router.push({
          path:'/zhaobiao'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
 .gxjy{
    background: #f5f5f5;
   /deep/ .el-button:hover{
     color: #0c8aff;
   }
   .xfsc{
     /deep/ .el-tabs__item{
       font-size: 24px;
       color: #eee;
     }
     /deep/.el-tabs__nav-wrap::after{
       height: 1px;
       background-color:#D9D9D9!important;
     }
     /deep/.el-tabs__active-bar{
       background-color: #fff;
     }
     /deep/ .el-tabs__item.is-active{
       font-size: 24px;
       color: #fff!important;
     }
   }
   /deep/ .el-table th.is-leaf{
     background-color: #fff!important;
   }
   .xfscbtn{
     display: inline-block;
     margin-top: 50px;
     padding: 10px 18px;
     background: #fff;
     border-radius: 4px;
     text-align: center;
     color: #1D5CDF;
     cursor: pointer;
   }
   .img1{
     background: url("../../assets/gxjy/img1.png") no-repeat;
     background-size: cover;
   }
    .hdimage{
      width: 145px;
      height: 360px;
      margin-right: 8px;
      transition: all 0.2s ease-in-out;
    }
   .remove-class{
     width: 585px;
   }
   .zixun{
     padding: 40px;
     margin-bottom: 40px;
     .zixuntitle{
       font-size: 24px;
     }
     .zixuncontent{
       position: relative;
       margin-top: 25px;
     }
     .zixunbtn{
       position: absolute;
       top: 0;
       right: 0;
     }
   }
   .gong{
     overflow: hidden;
     .glist{
       float: left;
       margin-bottom: 15px;
     }
     .gxtitle{
       width: 385px;
     }
     .img{
       width: 176px;
       height: 132px;
       margin-right: 15px;
     }
   }
   .bgf1{
     background: #F0F5FE;
     position: relative;
     padding-bottom: 40px;
     .gytitle{
       font-size: 24px;
       text-align: center;
       padding: 40px 0;
     }
     .gyimage{
       width: 589px;
       height: 314px;
       margin: 0 10px;
       position: relative;
     }
     .gybtn{
       display: inline-block;
       background: #fff;
       color: #409EFF;
       border-radius: 4px;
       padding: 10px 25px;
       cursor: pointer;
       position: absolute;
       left:50%;
       margin-left: -50px;
       bottom:25px
     }
   }
   .bgf{
     background: #fff;
     position: relative;
   }
   .bgall{
     background: #fff;
     padding:35px 0;
   }
   .fenye{
     text-align: center;
     padding: 30px 0;
     background: #fff;
     display: inline-block;
     width: 100%;
   }
   .xfscbg1{
     height: 358px;
     width: 51%;
     background: url("../../assets/gxjy/img2.png") no-repeat;
     background-size: 100% 100%;
     position: absolute;
     left: 0;
     top: 220px;
     z-index: 0;
   }
   .xfsc{
     height: 500px;
     position: relative;
     .xfsch1{
       margin-top: 105px;
       width: 350px;
       color: #fff;
       z-index: 2;
     }
   }
   .gfsc,.xfsc{
     padding: 40px 0;
     .gfsctitle{
       font-size: 24px;
       margin-right: 10px;
     }
   }
   .xfsc{
     padding: 70px 0;
   }
   .shichang{
     background: #fff;
     margin-top: 30px;
     .scleft{
       width: 186px;
       margin:10px;
       padding-right:10px ;
       border-right: 1px solid #eee;
       .ul{
         padding: 15px 18px;
         cursor: pointer;
       }
       .checked{
         background: #0c8aff;
         color: #fff;
         border-radius: 4px;
       }
     }
     .ul1{
       text-align: center;
       width: 303px;
       float: left;
       cursor: pointer;
       .images{
         width: 271px;
         height: 198px;
       }
       .ultitle{
         margin: 10px;
       }
     }
   }
    .content{
      width: 1200px;
      margin: 0 auto;
      .wyrz{
        background: #409EFF;
        color: #fff;
        border-radius: 4px;
        padding:35px 20px;
        margin-top: 25px;
        .btn{
          display: inline-block;
          background: #fff;
          color: #409EFF;
          border-radius: 4px;
          padding: 10px 25px;
          float: right;
          margin-top: -10px;
          cursor: pointer;
        }
      }
      .tagright{
        position: absolute;
        right: -12px;
        top: -12px;
        color: #fff;
        font-size: 14px;
      }
      .bg1{
        background: linear-gradient(0deg,#FFFFFF,#FEF1F1);
      }
      .bg2{
        background: linear-gradient(0deg,#FFFFFF,#DFF0FD);
      }
      .color1{
        color:#BD1D13;
        font-size: 24px;
        margin: 10px 0;
      }
      .color2{
        font-size: 24px;
        color:#0D72AA;
        margin: 10px 0;

      }
      .toplist{
        padding: 10px 19px;
        width: 343px;
        .gxbg1:before {
          content: '';
          position: absolute;
          top: -20px;
          right: -20px;
          width: 0;
          height: 0;
          border-top: 50px solid #BD1D13;
          border-radius: 4px;
          border-left: 50px solid transparent;
        }
        .gxbg2:before {
          content: '';
          position: absolute;
          top: -20px;
          right: -20px;
          width: 0;
          height: 0;
          border-top: 50px solid #008C49;
          border-radius: 4px;
          border-left: 50px solid transparent;
        }
        .gxbottom{
          margin-top: 8px;
          font-size: 12px;
          text-align: center;
          position: relative;
          .tag1{
            position: absolute;
            left: 0px;
            top: -10px;
          }
        }
        .gxchanpin{
          font-size: 16px;
          font-weight: bolder;
        }
        .number{
          margin-top: 10px;
        }
        .tiao{
          color: #F37500;
        }
      }
    }

  }
</style>